import MessageEnum, { getName } from "../../../../packages/framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { BlockComponent } from "../../../../packages/framework/src/BlockComponent";
import { FormikValues } from "formik";
import { getStorageData, setStorageData } from "../../../../packages/framework/src/Utilities";

type BusinessField =
    "Business profile"
    | "Legal"
    | "User management"
    | null;

type ProfileField =
    "Account settings" |
    "Notification"
    | "Language" |
    null

type BusinessSettingsFieldsType =
    "General Business Information" | "Payment and Banking Information" | "About Us Information" | null
type LegalSettingsFieldType =
    "Terms and Conditions" | "Payment Policy" | "Cancellation Policy" | "Location Instructions"

interface BankingResponseJson {
    data: {
        data: {
            id: string;
            type: string;
            attributes: {
                bank_name: string;
                account_holder_name: string;
                account_number: string;
                is_verified: boolean;
                IBAN: string;
                swift: string;
                sort_code: string;
                routing_number: string;
            };
        };
    };
    message: string;
}

interface FormikBankingValues {
    bank_name: string;
    account_holder_name: string;
    account_number: string;
    is_verified: boolean;
    IBAN: string;
    swift: string;
    sort_code: string;
    routing_number: string;
}

interface UserAttributes {
    activated: boolean;
    country_code: string | null;
    email: string;
    first_name: string | null;
    full_phone_number: string;
    last_name: string | null;
    phone_number: string | null;
    job_title: string;
    user_role: string;
    permission: string;
    type: string;
    device_id: string | null;
    unique_auth_id: string;
    parent_id: number;
}

interface User {
    id: string;
    type: string;
    attributes: UserAttributes;
}

interface UserListResponse {
    message: string;
    users: {
        data: User[];
    };
}

interface UserArray {
    fullName: string;
    userRole: string;
    jobTitle: string;
    email: string;
    phoneNumber: string;
    username: string;
    password: string;
    accessPermission: string;
}

interface HotelLegalPoliciesAttributes {
    terms_and_conditions: string;
    payment_policy: string;
    cancellation_policy: string;
    location_instructions: string;
}

interface HotelLegalPolicies {
    id: string;
    type: "hotel_legal_policies";
    attributes: HotelLegalPoliciesAttributes;
}

interface Data {
    data: HotelLegalPolicies[];
}

interface NotificationAttributes {
    gps_location: boolean;
    bluetooth: boolean;
    language: string;
    currency: string;
    allow_push_notification: boolean;
    push_notification: boolean;
    email_notifications: boolean;
    notification_type: string[];
}

interface NotificationData {
    id: string;
    type: string;
    attributes: NotificationAttributes;
}

interface Notifications {
    data: NotificationData;
}

interface NotificationResponse {
    message: string;
    notifications: Notifications;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    // Customizable Area Start
    businessActiveField: BusinessField
    BusinessSettings: boolean
    profileActiveField: ProfileField
    ProfileSettings: boolean
    BusinessSettingsFields: BusinessSettingsFieldsType
    businessProfile: boolean
    aboutUsInfo: string
    bankingFormikValues: FormikBankingValues
    LegalSettingsField: LegalSettingsFieldType
    termsAndConditions: string
    locationInstruction: string
    cancellationPolicy: string
    paymentPolicy: string
    addUserDialogBox: boolean
    userListArray: UserArray[]
    selectedUser: UserArray | null
    selectedUserIndex: number | null
    anchorEl: HTMLElement | null
    language: string
    allowPushNotification: boolean
    allowPushNotificationDevice: boolean
    allowEmail: boolean
    passwordDialogBox: boolean
    emailDialogBox: boolean
    email: string
    showOldPassword: boolean,
    showNewPassword: boolean,
    showConfirmPassword: boolean,
    old_password: string,
    new_password: string,
    confirm_password: string,
    showUpdateEmailDialogBox: boolean
    showOtpSection: boolean,
    otp: string,
    firstName: string;
    lastName: string
    confirmEmail: string
    newEmail: string
    password: string
    otpToken: string
    showSuccessDialog: boolean
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class HotelSettingsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    generalBusinessid: string = ""
    aboutUsId: string = ""
    getBankingId: string = ""
    updateBankingId: string = ""
    getUserListDetailsId: string = ""
    getPoliciesId: string = ""
    updatePoliciesId: string = ""
    updateNameId: string = ""
    updateEmailId: string = ""
    emailOtpId: string = ""
    emailOtpVerifcationId: string = ""
    emailChangeId: string = ""
    passwordChangeId: string = ""
    settingsId: string = ""
    getSettingsId: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            // Customizable Area Start
            BusinessSettings: true,
            businessActiveField: 'Business profile',
            profileActiveField: null,
            ProfileSettings: false,
            BusinessSettingsFields: 'General Business Information',
            businessProfile: true,
            aboutUsInfo: "",
            bankingFormikValues: {
                bank_name: "",
                account_holder_name: "",
                account_number: "",
                is_verified: false,
                IBAN: "",
                swift: "",
                sort_code: "",
                routing_number: ""
            },
            LegalSettingsField: 'Terms and Conditions',
            cancellationPolicy: "",
            locationInstruction: "",
            paymentPolicy: "",
            termsAndConditions: "",
            addUserDialogBox: false,
            userListArray: [],
            selectedUser: null,
            anchorEl: null,
            selectedUserIndex: null,
            language: "",
            allowPushNotification: false,
            allowPushNotificationDevice: false,
            allowEmail: true,
            emailDialogBox: false,
            passwordDialogBox: false,
            email: "yop@mail.com",
            showOldPassword: false,
            showConfirmPassword: false,
            showNewPassword: false,
            old_password: "",
            new_password: "",
            confirm_password: "",
            showOtpSection: false,
            showUpdateEmailDialogBox: false,
            otp: "",
            firstName: "",
            lastName: "",
            newEmail: "",
            confirmEmail: "",
            password: "",
            otpToken: "",
            showSuccessDialog: false
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            this.handleResponse(message)
        }

        // Customizable Area End
    }

    // Customizable Area Start

    async componentDidMount() {
        this.getBankingDetails()
        this.getUserListDetails()
        this.getPolicies()
        this.getSettings()
    }

    handleResponse = (message: Message) => {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        if (apiRequestCallId === this.getBankingId) {
            this.handleGetBankingResponse(responseJson);
        }
        if (apiRequestCallId === this.aboutUsId) {
            this.handleAboutUsInfoResponse(responseJson)
        }
        if (apiRequestCallId === this.getUserListDetailsId) {
            this.handleGetUserListDetailsResponse(responseJson)
        }
        if (apiRequestCallId === this.getPoliciesId) {
            this.handleGetPoliciesResponse(responseJson)
        }
        if (apiRequestCallId === this.updatePoliciesId) {
            this.getPolicies()
        }
        if (apiRequestCallId === this.updateEmailId) {
            this.handleEmailChangeResponse(responseJson)
        }
        if (apiRequestCallId === this.emailOtpId) {
            this.handleEmailOtpRequestResponse(responseJson)
        }
        if (apiRequestCallId === this.emailOtpVerifcationId) {
            this.handleEmailOtpVerificationResponse(responseJson)
        }
        if (apiRequestCallId === this.passwordChangeId) {
            this.handlePasswordChangeRequestResponse(responseJson)
        }
        if (apiRequestCallId === this.getSettingsId) {
            this.handleGetSettingsResponse(responseJson)
        }

    }

    handleBusinessFieldClick = (field: BusinessField) => {
        this.setState({
            BusinessSettings: true,
            businessActiveField: field,
            ProfileSettings: false,
            profileActiveField: null,
        })
    }

    handleProfileFieldClick = (field: ProfileField) => {
        this.setState({
            BusinessSettings: false,
            businessActiveField: null,
            ProfileSettings: true,
            profileActiveField: field,
        })
    }

    handleBusinessTabChange = (event: React.ChangeEvent<{}>, newValue: BusinessSettingsFieldsType) => {
        this.setState({ BusinessSettingsFields: newValue });
    };

    handleLegalTabChange = (event: React.ChangeEvent<{}>, newValue: LegalSettingsFieldType) => {
        this.setState({ LegalSettingsField: newValue });
    };

    handleGeneralBusinessFormikValues = async (values: FormikValues) => {
        const obj = {
            "business_name": values.businessName,
            "business_address": values.doorNumber,
            "vat_number": values.vatNumber,
            "description": values.additionalInfo,
            "city": values.city,
            "country": values.country,
            "post_code": values.postcode,
            "building_name": values.doorNumber,
            "street": values.street
        }
        const token = await getStorageData("token")
        const header = {
            "Content-Type": configJSON.applicationApiContentType,
            "token": token
        };
        const ApiRes = new Message(getName(MessageEnum.RestAPIRequestMessage));

        this.generalBusinessid = ApiRes.messageId;

        ApiRes.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.generalBusinessEndpoint
        );
        ApiRes.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        ApiRes.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(obj)
        );
        ApiRes.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.patchMethodType
        );
        runEngine.sendMessage(ApiRes.id, ApiRes);
    }

    getBankingDetails = async () => {
        const token = await getStorageData("token")
        const header = {
            "token": token
        };
        const ApiRes = new Message(getName(MessageEnum.RestAPIRequestMessage));

        this.getBankingId = ApiRes.messageId;

        ApiRes.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getBankingEndpoint
        );
        ApiRes.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        ApiRes.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(ApiRes.messageId, ApiRes);
    }

    handleGetBankingResponse = (responseJson: BankingResponseJson) => {
        const response = responseJson.data.data.attributes
        const obj = {
            bank_name: response.bank_name,
            account_holder_name: response.account_holder_name,
            account_number: response.account_number,
            is_verified: response.is_verified,
            IBAN: response.IBAN,
            swift: response.swift,
            sort_code: response.sort_code,
            routing_number: response.routing_number
        }

        this.setState({ bankingFormikValues: obj })
    }

    handleBankingFormikValues = (values: FormikValues) => {

    }

    handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({ aboutUsInfo: e.target.value })
    }

    handleTermsAndConditionsInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({ termsAndConditions: e.target.value })
    }


    handlePaymentPolicyInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({ paymentPolicy: e.target.value })
    }


    handleCancellationPolicyInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({ cancellationPolicy: e.target.value })
    }


    handleLocationInstructionInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({ locationInstruction: e.target.value })
    }

    handlePolicies = async () => {
        const obj = {
            "data": {
                "attributes": {
                    "terms_and_conditions": this.state.termsAndConditions,
                    "payment_policy": this.state.paymentPolicy,
                    "cancellation_policy": this.state.cancellationPolicy,
                    "location_instructions": this.state.locationInstruction


                }
            }
        }

        const token = await getStorageData("token")
        const header = {
            "Content-Type": configJSON.applicationApiContentType,
            "token": token
        };
        const ApiRes = new Message(getName(MessageEnum.RestAPIRequestMessage));

        this.updatePoliciesId = ApiRes.messageId;

        ApiRes.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.updatePoliciesEndPoint
        );
        ApiRes.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        ApiRes.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(obj)
        );
        ApiRes.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.patchMethodType
        );
        runEngine.sendMessage(ApiRes.id, ApiRes);
    }

    getPolicies = async () => {
        const token = await getStorageData("token")
        const header = {
            "token": token
        };
        const ApiRes = new Message(getName(MessageEnum.RestAPIRequestMessage));

        this.getPoliciesId = ApiRes.messageId;

        ApiRes.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getPoliciesEndPoint
        );
        ApiRes.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        ApiRes.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(ApiRes.messageId, ApiRes);
    }

    handleGetPoliciesResponse = (responseJson: Data) => {
        const data = responseJson.data[0].attributes
        this.setState({
            termsAndConditions: data.terms_and_conditions,
            paymentPolicy: data.payment_policy,
            cancellationPolicy: data.cancellation_policy,
            locationInstruction: data.location_instructions
        })
    }

    handleAboutUsInfo = async () => {
        const obj = {
            "about_us": this.state.aboutUsInfo
        }
        const token = await getStorageData("token")
        const header = {
            "Content-Type": configJSON.applicationApiContentType,
            "token": token
        };
        const ApiRes = new Message(getName(MessageEnum.RestAPIRequestMessage));

        this.aboutUsId = ApiRes.messageId;

        ApiRes.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.aboutUsEndpoint
        );
        ApiRes.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        ApiRes.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(obj)
        );
        ApiRes.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.patchMethodType
        );
        runEngine.sendMessage(ApiRes.id, ApiRes);

    }

    handleAboutUsInfoResponse = (responseJson: { message: string }) => {

    }

    getUserListDetails = async () => {
        const token = await getStorageData("token")
        const header = {
            "token": token
        };
        const ApiRes = new Message(getName(MessageEnum.RestAPIRequestMessage));

        this.getUserListDetailsId = ApiRes.messageId;

        ApiRes.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getUserListEndpoint
        );
        ApiRes.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        ApiRes.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getMethodType
        );
        runEngine.sendMessage(ApiRes.messageId, ApiRes);
    }

    handleGetUserListDetailsResponse = (responseJson: UserListResponse) => {
        const usersArray: UserArray[] = responseJson.users.data.map(user => ({
            fullName: `${user.attributes.first_name} ${user.attributes.last_name}`.trim(),
            userRole: user.attributes.user_role,
            jobTitle: user.attributes.job_title,
            email: user.attributes.email,
            phoneNumber: user.attributes.full_phone_number,
            username: user.attributes.email.split('@')[0],
            password: "",
            accessPermission: user.attributes.permission
        }));
        this.setState({ userListArray: usersArray })

    }

    handleAddUserDialog = () => {
        this.setState({
            addUserDialogBox: true,
            selectedUser: null,
            selectedUserIndex: null,
        });
    };

    closeAddUserDialog = () => {
        this.setState({
            addUserDialogBox: false
        })
    }


    handleSaveUser = (values: UserArray) => {
        const { selectedUserIndex, userListArray } = this.state;

        if (selectedUserIndex !== null) {
            const updatedUserList = [...userListArray];
            updatedUserList[selectedUserIndex] = values;
            this.setState({
                userListArray: updatedUserList,
                selectedUserIndex: null,
                selectedUser: null,
            });
        } else {
            this.setState((prevState) => ({
                userListArray: [...prevState.userListArray, values],
            }));
        }
        this.closeAddUserDialog();
    };

    handleEditUser = () => {
        const { selectedUserIndex } = this.state;
        if (selectedUserIndex !== null) {
            this.setState({
                addUserDialogBox: true,
            });
        }
        this.handleMenuClose();
    };

    handleDeleteUser = () => {
        const { selectedUserIndex, userListArray } = this.state;
        const filteredUsers = userListArray.filter((_, i) => i !== selectedUserIndex);
        this.setState({
            userListArray: filteredUsers,
            selectedUserIndex: null,
            selectedUser: null,
            addUserDialogBox: false,
        });
        this.handleMenuClose();
    };

    handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>, index: number) => {
        this.setState({
            anchorEl: event.currentTarget,
            selectedUser: this.state.userListArray[index],
            selectedUserIndex: index
        });
    };

    handleMenuClose = () => {
        this.setState({ anchorEl: null });
    };


    handleFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ firstName: event.target.value })
    }

    handleLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ lastName: event.target.value })
    }

    handleAccountSettings = async () => {
        const obj = {
            "data":
            {
                "first_name": this.state.firstName,
                "last_name": this.state.lastName

            }
        }
        const token = await getStorageData("token")
        const header = {
            "Content-Type": configJSON.applicationApiContentType,
            "token": token
        };
        const ApiRes = new Message(getName(MessageEnum.RestAPIRequestMessage));

        this.updateNameId = ApiRes.messageId;

        ApiRes.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.updateNameEndPoint
        );
        ApiRes.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        ApiRes.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(obj)
        );
        ApiRes.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.patchMethodType
        );
        runEngine.sendMessage(ApiRes.id, ApiRes);
    }

    openEmailDialogBox = () => {
        this.setState({ emailDialogBox: true })
    }

    handleEmailChange = (values: FormikValues) => {
        this.setState({
            showUpdateEmailDialogBox: true,
            newEmail: values.email,
            confirmEmail: values.confirmEmail,
            password: values.passwoed
        })
        this.closeEmailDialogBox()
    }

    handleEmailChangeResponse = (responseJson: { message: string, user: { email: string } }) => {
        if (responseJson.message === "Email successfully updated") {
            this.setState({ email: responseJson.user.email })
            this.handleEmailOtpRequest()
        }
    }

    handleEmailDialogContinue = async () => {
        const obj = {
            "data":
            {
                "new_email": this.state.newEmail,
                "confirm_new_email": this.state.confirmEmail
            }
        }
        const token = await getStorageData("token")
        const header = {
            "Content-Type": configJSON.applicationApiContentType,
            "token": token
        };
        const ApiRes = new Message(getName(MessageEnum.RestAPIRequestMessage));

        this.updateEmailId = ApiRes.messageId;

        ApiRes.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.emailChangeEndPoint
        );
        ApiRes.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        ApiRes.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(obj)
        );
        ApiRes.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.patchMethodType
        );
        runEngine.sendMessage(ApiRes.id, ApiRes);
        this.setState({ showOtpSection: true })
        this.closeUpdateEmailDialogBox()
    }

    closeUpdateEmailDialogBox = () => {
        this.setState({ showUpdateEmailDialogBox: false })
    }

    closeEmailDialogBox = () => {
        this.setState({ emailDialogBox: false })
    }

    setOtp = (otp: string) => {
        this.setState({ otp })
    }

    reVerify = () => {
        this.handleEmailDialogContinue()
        this.setState({ otp: "" })
    }

    handleEmailOtpRequest = async () => {
        const data = {
            data: {
                type: "update_email",
                attributes: {
                    email: this.state.email
                }
            }
        }

        const header = {
            "Content-Type": configJSON.applicationApiContentType,
        };
        const ApiRes = new Message(getName(MessageEnum.RestAPIRequestMessage));

        this.emailOtpId = ApiRes.messageId;

        ApiRes.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.otpEndPoint
        );
        ApiRes.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        ApiRes.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(data)
        );
        ApiRes.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postMethodType
        );
        runEngine.sendMessage(ApiRes.id, ApiRes);
    }

    handleEmailOtpRequestResponse = (responseJson: { meta: { token: string } }) => {
        if (responseJson.meta.token) {
            this.setState({ otpToken: responseJson.meta.token })
        }
    }

    handleEmailOtpVerification = async () => {
        const data = {
            data: {
                otp_code: this.state.otp,
                token: this.state.otpToken,
            }
        }
        const header = {
            "Content-Type": configJSON.applicationApiContentType,
        };
        const ApiRes = new Message(getName(MessageEnum.RestAPIRequestMessage));

        this.emailOtpVerifcationId = ApiRes.messageId;

        ApiRes.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.otpConfirmationEndPoint
        );
        ApiRes.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        ApiRes.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(data)
        );
        ApiRes.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postMethodType
        );
        runEngine.sendMessage(ApiRes.id, ApiRes);
    }

    handleEmailOtpVerificationResponse = (responseJson: { messages: [{ otp: string, token: string }] }) => {
        if (responseJson.messages[0].otp === "Email verified") {
            setStorageData('token', responseJson.messages[0].token)
            this.setState({ showSuccessDialog: true, showOtpSection: false })
        }
    }

    openPasswordDialogBox = () => {
        this.setState({ passwordDialogBox: true })
    }

    closePasswordDialogBox = () => {
        this.setState({ passwordDialogBox: false })
    }

    handleToggle = (value: number) => {
        if (value === 1) {
            this.setState((prev) => ({ showOldPassword: !prev.showOldPassword, }))
        } else if (value === 2) {
            this.setState((prev) => ({ showNewPassword: !prev.showNewPassword }))
        }
        else if (value === 3) {
            this.setState((prev) => ({ showConfirmPassword: !prev.showConfirmPassword }))
        }
    }


    handlePasswordChange = (values: { old_password: string, new_password: string, confirm_password: string }, { resetForm }: any) => {
        this.setState({
            old_password: values.old_password,
            new_password: values.new_password,
            confirm_password: values.confirm_password
        },
            () => this.handlePasswordChangeRequest()
        )
        resetForm()
        this.closePasswordDialogBox()
    }

    handlePasswordChangeRequest = async () => {
        const userData = {
            "data": {
                "old_password": this.state.old_password,
                "new_password": this.state.new_password,
                "confirm_password": this.state.confirm_password
            }
        }
        const token = await getStorageData("token")
        const header = {
            "Content-Type": configJSON.applicationApiContentType,
            "token": token
        };
        const ApiRes = new Message(getName(MessageEnum.RestAPIRequestMessage));

        this.passwordChangeId = ApiRes.messageId;

        ApiRes.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.passChangeEndPoint + "password"
        );
        ApiRes.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        ApiRes.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(userData)
        );
        ApiRes.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.patchMethodType
        );
        runEngine.sendMessage(ApiRes.id, ApiRes);
    }

    handlePasswordChangeRequestResponse = (responseJson: { message: string }) => {
        if (responseJson.message === "Password successfully updated") {
            this.setState({ showSuccessDialog: true, })
        }
    }

    handleDeleteAccount = () => {

    }

    handleLanguageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ language: event.target.value })
    }

    handleAllowPushNotificationDevice = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ allowPushNotificationDevice: event.target.checked })
    }

    handleAllowPushNotification = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ allowPushNotification: event.target.checked })
    }

    handleAllowEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ allowEmail: event.target.checked })
    }

    handleSettings = async () => {
        const obj = {
            "account": {
                "language": this.state.language,
                "allow_push_notification": this.state.allowPushNotificationDevice,
                "push_notification": this.state.allowPushNotification,
                "email_notifications": this.state.allowEmail
            }
        }
        const token = await getStorageData("token")
        const header = {
            "Content-Type": configJSON.applicationApiContentType,
            "token": token
        };
        const ApiRes = new Message(getName(MessageEnum.RestAPIRequestMessage));

        this.settingsId = ApiRes.messageId;

        ApiRes.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.updateSettings
        );
        ApiRes.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        ApiRes.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(obj)
        );
        ApiRes.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.patchMethodType
        );
        runEngine.sendMessage(ApiRes.id, ApiRes);

    }
    getSettings = async () => {
        const token = await getStorageData("token")
        const header = {
            "Content-Type": configJSON.applicationApiContentType,
            "token": token
        };
        const ApiRes = new Message(getName(MessageEnum.RestAPIRequestMessage));

        this.getSettingsId = ApiRes.messageId;

        ApiRes.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.updateSettings
        );
        ApiRes.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        ApiRes.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(ApiRes.id, ApiRes);
    }
    handleGetSettingsResponse = (response: NotificationResponse) => {
        const data = response.notifications.data.attributes
        this.setState({
            allowEmail: data.email_notifications,
            allowPushNotification: data.push_notification,
            allowPushNotificationDevice: data.allow_push_notification,
            language: data.language
        })
    }
    // Customizable Area End
}
