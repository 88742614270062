Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "settings2";
exports.labelBodyText = "settings2 Body";
exports.btnExampleTitle = "CLICK ME";
exports.applicationApiContentType = "application/json";
exports.patchMethodType = "PATCH"
exports.deleteMethodType = "DELETE"
exports.getMethodType = "GET"
exports.postMethodType = "POST"
exports.putMethodType = "PUT"
exports.DeleteMethodType = "DELETE"
exports.nameChangeEndPoint = "bx_block_settings/change_name"
exports.emailChangeEndPoint = "bx_block_settings/change_email"
exports.getDestinationEndPoint = "bx_block_settings/show_destinations"
exports.getActivitiesEndPoint = "bx_block_settings/show_activities"
exports.getAccommodationEndPoint = "bx_block_settings/show_accommodations"
exports.passChangeEndPoint = "bx_block_settings/change_"
exports.otpEndPoint = "bx_block_settings/otps"
exports.otpConfirmationEndPoint = "bx_block_settings/otp_confirmations"
exports.updateDestinationEndPoint = "bx_block_settings/update_destinations"
exports.updateActivityEndPoint = "bx_block_settings/update_activities"
exports.updateAccommodationEndPoint = "bx_block_settings/update_accommodations"
exports.deleteAccountEndPoint = "bx_block_settings/destroy"
exports.getTermsEndPoint = "bx_block_terms_and_conditions/admin_terms_conditions/terms_and_conditions"
exports.getPrivacyEndPoint = "bx_block_terms_and_conditions/admin_terms_conditions/privacy_policies"
exports.generalBusinessEndpoint = "bx_block_settings/hotel_settings/update_business_info"
exports.aboutUsEndpoint = "bx_block_settings/hotel_settings/update_about_us"
exports.getBankingEndpoint = "bx_block_settings/hotel_settings/show_bank_details"
exports.getUserListEndpoint = "bx_block_settings/hotel_settings/list_all_users"
exports.updatePoliciesEndPoint = "bx_block_settings/hotel_settings/update_legal"
exports.createPoliciesEndPoint = "bx_block_settings/hotel_settings/create_legal"
exports.getPoliciesEndPoint = "bx_block_settings/hotel_settings/show_legal"
exports.updateNameEndPoint="bx_block_settings/hotel_settings/update_name"
exports.updateSettings="bx_block_settings/hotel_settings"
// Customizable Area End