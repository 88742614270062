import React from "react";
// ...

// Customizable Area Start
import { ThemeProvider, styled } from "@material-ui/styles";
import { Box, Button, Dialog, DialogContent, Divider, FormControlLabel, Grid, IconButton, InputAdornment, Menu, MenuItem, Paper, Radio, RadioGroup, Tab, Tabs, TextField, TextareaAutosize, Typography, createTheme } from "@material-ui/core";
import { ic_background, ic_business, ic_logout, ic_notifications, ic_profile, ic_userManagement } from "./assets";
import * as Yup from 'yup'
import HotelNavigationMenu from "../../../../packages/blocks/navigationmenu/src/HotelNavigationMenu.web";
import { ErrorMessage, Field, Form, Formik } from "formik";

const theme = createTheme({
  palette: {
    primary: {
      main: "#FAA81D",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
  },
});


type BusinessField =
  "Business profile"
  | "Legal"
  | "User management"
  | null;

type ProfileField =
  "Account settings" |
  "Language" |
  "Notification" | null

const BusinessSettingsfields: BusinessField[] = [
  "Business profile",
  "Legal",
  "User management"
]

const ProfileSettingsfields: ProfileField[] = [
  "Account settings",
  "Language",
  "Notification"
]

export const BpIcon = styled('span')({
  borderRadius: '50%',
  width: '24px',
  height: '24px',
  boxShadow: 'inset 0 0 0 1px gray, inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: '#fff',
});

export const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#FAA81D',
  color: '#FAA81D',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  boxShadow: 'inset 0 0 0 2px #FAA81D, inset 0 -2px 0 rgba(16,22,26,.1)',
  '&::before': {
    display: 'block',
    width: '24px',
    height: '24px',
    backgroundImage: 'radial-gradient(#FFFFFF,#FFFFFF 28%,transparent 32%)',
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#FAA81D',
  },
});

const GeneralBusinessInformationValidationSchema = Yup.object({
  businessName: Yup.string().required('Business name is required'),
  vatNumber: Yup.string().required('VAT Number is required'),
  additionalInfo: Yup.string().max(500, 'Maximum 500 characters allowed'),
  doorNumber: Yup.string().required('Door number/Building name is required'),
  street: Yup.string().required('Street is required'),
  postCode: Yup.string().required('Post code is required'),
  city: Yup.string().required('City is required'),
  country: Yup.string().required('Country/Region is required'),
  contactNumber: Yup.string().required('Contact number is required'),
  supportEmail: Yup.string().email('Invalid email format').required('Support email is required'),
});

const BankingValidationSchema = Yup.object().shape({
  bank: Yup.string()
    .required('Bank name is required'),

  accountHolderName: Yup.string()
    .required('Account holder\'s name is required'),

  accountNumber: Yup.string()
    .required('Account number is required')
    .matches(/^\d+$/, 'Account number must be numeric')
    .min(7, 'Account number must be at least 7 digits')
    .max(10, 'Account number must be less than 10 digits'),

  iban: Yup.string()
    .required('IBAN is required')
    .matches(/^[A-Z0-9]+$/, 'IBAN must be alphanumeric')
    .length(22, 'IBAN must be exactly 22 characters'),

  swift: Yup.string()
    .required('SWIFT code is required')
    .matches(/^[A-Z0-9]{8,11}$/, 'SWIFT code must be 8 or 11 characters long'),

  sortCode: Yup.string()
    .required('Sort code is required')
    .matches(/^\d{2}-\d{2}-\d{2}$/, 'Sort code must be in the format XX-XX-XX'),

  routingNumber: Yup.string()
    .required('Routing number is required')
    .matches(/^\d{9}$/, 'Routing number must be exactly 9 digits'),
});

const addUserSchema = Yup.object().shape({
  fullName: Yup.string().required('Full Name is required'),
  userRole: Yup.string().required('User Role is required'),
  jobTitle: Yup.string().required('Job Title is required'),
  email: Yup.string().email('Invalid email address').required('Email Address is required'),
  phoneNumber: Yup.string().required('Phone Number is required'),
  username: Yup.string().required('Username is required'),
  password: Yup.string().required('Password is required'),
  accessPermission: Yup.string().required('Access Permission is required'),
})

const passwordValidationSchema = Yup.object().shape({
  old_password: Yup.string()
    .required('Old password is required'),
  new_password: Yup.string()
    .min(8, 'New password must be at least 8 characters long')
    .required('New password is required'),
  confirm_password: Yup.string()
    .oneOf([Yup.ref('new_password'), null], 'Passwords must match')
    .required('Please confirm your new password'),
})

const CustomTabs = styled(Tabs)({
  display: 'flex',
  justifyContent: 'start',
  borderRadius: 5,
  backgroundColor: '#f6f8fa',
  '& .MuiTabs-indicator': {
    display: 'none',
  },
});

const CustomTab = styled(Tab)({
  textTransform: 'none',
  fontSize: theme.typography.pxToRem(15),
  borderRadius: '10px',
  color: '#64748B',
  '&.Mui-selected': {
    color: '#FAA81D',
    backgroundColor: '#fff',
    fontWeight: 700,
    fontSize: '16px',
    fontFamily: 'LeagueSpartan , sans-serif',
    border: '1px solid #CBD5E1'
  },
});

const emailValidationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email address').required('Email is required'),
  confirmEmail: Yup.string()
    .oneOf([Yup.ref('email'), null], 'Emails must match')
    .required('Confirm email is required'),
  password: Yup.string().required('Password is required'),
});

// Customizable Area End

import HotelSettingsController, {
  Props,
} from "./HotelSettingsController";
import { AddCircleOutline, Clear, DeleteOutline, Edit, MoreVert, Visibility, VisibilityOff } from "@material-ui/icons";
import { SwitchBtn } from "./Settings2.web";
import OTPInput from "react-otp-input";

export default class HotelSettings extends HotelSettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  BusinessProfile = () => {
    const { BusinessSettingsFields } = this.state;
    switch (BusinessSettingsFields) {
      case 'General Business Information':
        return this.renderGeneralBusinessInformation();
      case 'Payment and Banking Information':
        return this.renderPaymentAndBankingInformation();
      case 'About Us Information':
        return this.renderAboutUsInformation();
    }
  }

  LegalSettings = () => {
    const { LegalSettingsField } = this.state;
    switch (LegalSettingsField) {
      case 'Terms and Conditions':
        return this.renderTermsAndConditions();
      case 'Payment Policy':
        return this.renderPaymentPolicy();
      case 'Cancellation Policy':
        return this.renderCancellationPolicy();
      case 'Location Instructions':
        return this.renderLocationInstruction();
    }
  }

  renderGeneralBusinessInformation = () => {
    return (
      <Formik
        initialValues={{
          businessName: '',
          vatNumber: '',
          additionalInfo: '',
          doorNumber: '',
          street: '',
          postCode: '',
          city: '',
          country: '',
          contactNumber: '',
          supportEmail: '',
        }}
        validationSchema={GeneralBusinessInformationValidationSchema}
        onSubmit={this.handleGeneralBusinessFormikValues}
      >
        {({ handleSubmit, values }) => (
          <Form onSubmit={handleSubmit}>
            <Paper elevation={2} >
              <Box paddingTop={"40px"} style={{ gap: "24px", display: "flex", flexDirection: "column" }}>
                <Box style={{ gap: "16px", display: "flex", flexDirection: "column" }}>
                  <Typography style={{ ...webStyle.leftHeadings, padding: "0px 40px" }} className="LeagueSpartan">General Business Information</Typography>

                  <Box display="flex" style={{
                    justifyContent: " space-between",
                    gap: "16px",
                    padding: "0px 40px"
                  }}>
                    <Box width={"60%"}>
                      <Typography style={webStyle.BusinessSettingsSubHeadingTypography} className="LeagueSpartan">Business name</Typography>
                      <Field
                        as={TextField}
                        name="businessName"
                        variant="outlined"
                        type="text"
                        fullWidth
                      />
                      <ErrorMessage name="businessName" component="div" style={{ color: 'red' }} />
                    </Box>
                    <Box width={"40%"}>
                      <Typography style={webStyle.BusinessSettingsSubHeadingTypography} className="LeagueSpartan">VAT Number</Typography>
                      <Field
                        as={TextField}
                        name="vatNumber"
                        variant="outlined"
                        type="text"
                        fullWidth
                      />
                      <ErrorMessage name="vatNumber" component="div" style={{ color: 'red' }} />
                    </Box>
                  </Box>

                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      padding: '0px 40px'
                    }}
                  >
                    <Typography style={webStyle.BusinessSettingsSubHeadingTypography} className="LeagueSpartan">Description of service</Typography>
                    <Field
                      as={TextareaAutosize}
                      name="additionalInfo"
                      minRows={4}
                      className="textarea LeagueSpartan"
                    />
                    <ErrorMessage name="additionalInfo" component="div" style={{ color: 'red' }} />
                    <Typography style={{ alignSelf: "end", fontSize: '12px', fontWeight: 400, lineHeight: '18px' }} className="LeagueSpartan">
                      {values.additionalInfo?.length || 0}/500</Typography>
                  </Box>
                </Box>

                <Box style={{ gap: "16px", display: "flex", flexDirection: "column" }}>
                  <Typography className="LeagueSpartan" style={{ ...webStyle.leftHeadings, padding: "0px 40px" }}>Business Address</Typography>
                  <Box display="flex"
                    style={{
                      justifyContent: " space-between",
                      gap: "16px",
                      padding: "0px 40px",
                    }}
                  >
                    <Box width={"25%"}>
                      <Typography className="LeagueSpartan" style={webStyle.BusinessSettingsSubHeadingTypography}>Door number/ Building name</Typography>
                      <Field
                        as={TextField}
                        name="doorNumber"
                        variant="outlined"
                        type="text"
                        fullWidth
                      />
                      <ErrorMessage name="doorNumber" component="div" style={{ color: 'red' }} />

                    </Box>
                    <Box width={"50%"}>
                      <Typography style={webStyle.BusinessSettingsSubHeadingTypography} className="LeagueSpartan">Street*</Typography>
                      <Field
                        as={TextField}
                        name="street"
                        variant="outlined"
                        type="text"
                        fullWidth
                      />
                      <ErrorMessage name="street" component="div" style={{ color: 'red' }} />
                    </Box>
                    <Box width={"25%"}>
                      <Typography style={webStyle.BusinessSettingsSubHeadingTypography} className="LeagueSpartan">Post code*</Typography>
                      <Field
                        as={TextField}
                        name="postCode"
                        variant="outlined"
                        type="text"
                        fullWidth
                      />
                      <ErrorMessage name="postCode" component="div" style={{ color: 'red' }} />
                    </Box>
                  </Box>
                  <Box display="flex"
                    style={{
                      justifyContent: " space-between",
                      gap: "16px",
                      padding: "0px 40px"
                    }}
                  >
                    <Box width={"100%"}>
                      <Typography style={webStyle.BusinessSettingsSubHeadingTypography} className="LeagueSpartan">City*</Typography>
                      <Field
                        as={TextField}
                        name="city"
                        variant="outlined"
                        type="text"
                        fullWidth
                      />
                      <ErrorMessage name="city" component="div" style={{ color: 'red' }} />
                    </Box>
                    <Box width={"100%"}>
                      <Typography className="LeagueSpartan" style={webStyle.BusinessSettingsSubHeadingTypography}>Country/Region*</Typography>
                      <Field
                        as={TextField}
                        name="country"
                        variant="outlined"
                        type="text"
                        fullWidth
                      />
                      <ErrorMessage name="country" component="div" style={{ color: 'red' }} />
                    </Box>
                  </Box>
                </Box>

                <Box style={{ gap: "16px", display: "flex", flexDirection: "column" }}>
                  <Typography className="LeagueSpartan" style={{ ...webStyle.leftHeadings, padding: "0px 40px" }}>Contact Information</Typography>
                  <Box display="flex"
                    style={{
                      justifyContent: " space-between",
                      gap: "16px",
                      padding: "0px 40px"
                    }}
                  >
                    <Box width={"100%"}>
                      <Typography style={webStyle.BusinessSettingsSubHeadingTypography} className="LeagueSpartan">Contact Number</Typography>
                      <Field
                        as={TextField}
                        name="contactNumber"
                        variant="outlined"
                        type="tel"
                        fullWidth
                      />
                      <ErrorMessage name="contactNumber" component="div" style={{ color: 'red' }} />
                    </Box>
                    <Box width={"100%"}>
                      <Typography style={webStyle.BusinessSettingsSubHeadingTypography} className="LeagueSpartan">Support email</Typography>
                      <Field
                        as={TextField}
                        name="supportEmail"
                        variant="outlined"
                        type="email"
                        fullWidth
                      />
                      <ErrorMessage name="suppportEmail" component="div" style={{ color: 'red' }} />
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box paddingTop={"20px"}>
                <Divider />
              </Box>
              <Box display={"flex"} flexDirection={"column"} padding={"24px 40px"}>
                <Button
                  variant="contained"
                  style={{
                    color: '#fff',
                    backgroundColor: '#FAA81D',
                    alignSelf: 'end',
                    padding: '16px 24px',
                    fontWeight: 700,
                    borderRadius: '8px', float: 'right',
                    fontSize: '16px',
                  }}
                  data-test-id="saveChanges"
                  type="submit"
                >
                  Save
                </Button>
              </Box>
            </Paper>
          </Form>
        )
        }
      </Formik>
    );
  };

  renderPaymentAndBankingInformation = () => {
    const { bankingFormikValues } = this.state
    return (
      <Formik
        initialValues={{
          bank: bankingFormikValues.bank_name,
          accountHolderName: bankingFormikValues.account_holder_name,
          accountNumber: bankingFormikValues.account_number,
          iban: bankingFormikValues.IBAN,
          swift: bankingFormikValues.swift,
          sortCode: bankingFormikValues.sort_code,
          routingNumber: bankingFormikValues.routing_number,
        }}
        validationSchema={BankingValidationSchema}
        onSubmit={this.handleBankingFormikValues}
        enableReinitialize={true}
      >
        {({ handleSubmit, errors, touched, values }) => (
          <Form onSubmit={handleSubmit}>
            <Paper elevation={2}>
              <Box paddingTop={"40px"} style={{ gap: "24px", display: "flex", flexDirection: "column" }}>
                <Box style={{ gap: "16px", display: "flex", flexDirection: "column" }}>
                  <Typography className="LeagueSpartan" style={{ ...webStyle.leftHeadings, padding: "0px 40px" }}>
                    Payment and Banking Information
                  </Typography>

                  <Grid container spacing={2} style={{ padding: "0px 40px" }}>
                    <Grid item xs={12} sm={6}>
                      <Typography style={webStyle.BusinessSettingsSubHeadingTypography} className="LeagueSpartan">Bank</Typography>
                      <Field
                        as={TextField}
                        name="bank"
                        value={values.bank}
                        variant="outlined"
                        type="text"
                        fullWidth
                        error={touched.bank && Boolean(errors.bank)}
                        helperText={touched.bank && errors.bank}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography style={webStyle.BusinessSettingsSubHeadingTypography} className="LeagueSpartan">Account holder's name</Typography>
                      <Field
                        as={TextField}
                        name="accountHolderName"
                        variant="outlined"
                        type="text"
                        fullWidth
                        error={touched.accountHolderName && Boolean(errors.accountHolderName)}
                        helperText={touched.accountHolderName && errors.accountHolderName}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography style={webStyle.BusinessSettingsSubHeadingTypography} className="LeagueSpartan">Account Number</Typography>
                      <Field
                        as={TextField}
                        name="accountNumber"
                        variant="outlined"
                        // type="tel"
                        fullWidth
                        error={touched.accountNumber && Boolean(errors.accountNumber)}
                        helperText={touched.accountNumber && errors.accountNumber}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography style={webStyle.BusinessSettingsSubHeadingTypography} className="LeagueSpartan">IBAN</Typography>
                      <Field
                        as={TextField}
                        name="iban"
                        variant="outlined"
                        // type="text"
                        fullWidth
                        error={touched.iban && Boolean(errors.iban)}
                        helperText={touched.iban && errors.iban}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography style={webStyle.BusinessSettingsSubHeadingTypography} className="LeagueSpartan">SWIFT</Typography>
                      <Field
                        as={TextField}
                        name="swift"
                        variant="outlined"
                        // type="text"
                        fullWidth
                        error={touched.swift && Boolean(errors.swift)}
                        helperText={touched.swift && errors.swift}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography style={webStyle.BusinessSettingsSubHeadingTypography} className="LeagueSpartan">Sort Code</Typography>
                      <Field
                        as={TextField}
                        name="sortCode"
                        variant="outlined"
                        // type="tel"
                        fullWidth
                        error={touched.sortCode && Boolean(errors.sortCode)}
                        helperText={touched.sortCode && errors.sortCode}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography style={webStyle.BusinessSettingsSubHeadingTypography} className="LeagueSpartan">Routing Number</Typography>
                      <Field
                        as={TextField}
                        name="routingNumber"
                        variant="outlined"
                        // type="tel"
                        fullWidth
                        error={touched.routingNumber && Boolean(errors.routingNumber)}
                        helperText={touched.routingNumber && errors.routingNumber}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>

              <Box paddingTop={"20px"}>
                <Divider />
              </Box>
              <Box display={"flex"} flexDirection={"column"} padding={"24px 40px"}>
                <Button
                  variant="contained"
                  style={{
                    color: '#fff',
                    backgroundColor: '#FAA81D',
                    alignSelf: 'end',
                    padding: '16px 24px',
                    fontWeight: 700,
                    borderRadius: '8px',
                    float: 'right',
                    fontSize: '16px',
                  }}
                  data-test-id="saveChanges"
                  type="submit"
                >
                  Save
                </Button>
              </Box>
            </Paper>
          </Form>
        )}
      </Formik>
    );
  };

  renderAboutUsInformation = () => {
    return (
      <Paper elevation={2}>
        <Box paddingTop={"40px"} style={{ gap: "24px", display: "flex", flexDirection: "column" }}>
          <Box style={{ gap: "16px", display: "flex", flexDirection: "column" }}>
            <Typography style={{ ...webStyle.leftHeadings, padding: "0px 40px" }} className="LeagueSpartan">About Us Information</Typography>
          </Box>

          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '0px 40px',
              maxWidth: '100%'
            }}
          >
            <Typography style={webStyle.BusinessSettingsSubHeadingTypography} className="LeagueSpartan">Description of service</Typography>
            <TextareaAutosize
              className="textarea LeagueSpartan"
              data-test-id="handleInputChange"
              minRows={8}
              value={this.state.aboutUsInfo}
              onChange={this.handleInputChange}
            />
            <Typography style={{ alignSelf: "end", fontSize: '12px', fontWeight: 400, lineHeight: '18px' }}
              className="LeagueSpartan">{this.state.aboutUsInfo?.length || 0}/500</Typography>
          </Box>
        </Box>
        <Box paddingTop={"20px"}>
          <Divider />
        </Box>
        <Box display={"flex"} flexDirection={"column"} padding={"24px 40px"}>
          <Button
            variant="contained"
            style={webStyle.textAreaBtn}
            data-test-id="handleAboutUsInfo"
            onClick={this.handleAboutUsInfo}
          >
            Save
          </Button>
        </Box>
      </Paper>
    );
  };

  renderTermsAndConditions = () => {
    return (
      <Paper elevation={2}>
        <Box paddingTop={"40px"} style={{ gap: "24px", display: "flex", flexDirection: "column" }}>
          <Box style={{ gap: "16px", display: "flex", flexDirection: "column" }}>
            <Typography style={{ ...webStyle.leftHeadings, padding: "0px 40px" }} className="LeagueSpartan">Terms and Conditions</Typography>
          </Box>

          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              maxWidth: '100%',
              minHeight: '300px',
              padding: '0px 40px',
            }}
          >

            <TextareaAutosize
              className="textarea LeagueSpartan"
              data-test-id="handleTermsAndConditionsInputChange"
              minRows={8}
              style={{ maxWidth: '100%' }}
              value={this.state.termsAndConditions}
              onChange={this.handleTermsAndConditionsInputChange}
            />

            <Typography
              style={{ alignSelf: "end", fontSize: '12px', fontWeight: 400, lineHeight: '18px' }}
              className="LeagueSpartan"
            >{this.state.termsAndConditions?.length || 0}/500
            </Typography>
          </Box>
        </Box>
        <Box paddingTop={"20px"}>
          <Divider />
        </Box>
        <Box display={"flex"} flexDirection={"column"} padding={"24px 40px"}>
          <Button
            variant="contained"
            style={webStyle.textAreaBtn}
            data-test-id="handleTermsAndConditions"
            onClick={this.handlePolicies}
          >
            Save
          </Button>
        </Box>
      </Paper>
    );
  };

  renderPaymentPolicy = () => {
    return (
      <Paper elevation={2}>
        <Box paddingTop={"40px"} style={{ gap: "24px", display: "flex", flexDirection: "column" }}>
          <Box style={{ gap: "16px", display: "flex", flexDirection: "column" }}>
            <Typography style={{ ...webStyle.leftHeadings, padding: "0px 40px" }}
              className="LeagueSpartan">Payment Policy</Typography>
          </Box>

          <Box
            style={{
              minHeight: '300px',
              display: 'flex',
              flexDirection: 'column',
              padding: '0px 40px',
              maxWidth: '100%'
            }}
          >
            <TextareaAutosize
              className="textarea LeagueSpartan"
              data-test-id="handlePaymentPolicyInputChange"
              minRows={8}
              style={{ maxWidth: '100%' }}
              value={this.state.paymentPolicy}
              onChange={this.handlePaymentPolicyInputChange}
            />
            <Typography style={{ alignSelf: "end", fontSize: '12px', fontWeight: 400, lineHeight: '18px' }}
              className="LeagueSpartan">{this.state.paymentPolicy?.length || 0}/500</Typography>
          </Box>
        </Box>
        <Box paddingTop={"20px"}>
          <Divider />
        </Box>
        <Box display={"flex"} flexDirection={"column"} padding={"24px 40px"}>
          <Button
            variant="contained"
            style={webStyle.textAreaBtn}
            data-test-id="handlePaymentPolicy"
            onClick={this.handlePolicies}
          >
            Save
          </Button>
        </Box>
      </Paper>
    );
  };

  renderCancellationPolicy = () => {
    return (
      <Paper elevation={2}>
        <Box paddingTop={"40px"} style={{ gap: "24px", display: "flex", flexDirection: "column" }}>
          <Box style={{ gap: "16px", display: "flex", flexDirection: "column" }}>
            <Typography style={{ ...webStyle.leftHeadings, padding: "0px 40px" }} className="LeagueSpartan">Cancellation Policy</Typography>
          </Box>

          <Box
            style={{
              minHeight: '300px',
              display: 'flex',
              flexDirection: 'column',
              padding: '0px 40px',
              maxWidth: '100%'
            }}
          >
            <TextareaAutosize
              className="textarea LeagueSpartan"
              data-test-id="handleCancellationPolicyInputChange"
              minRows={8}
              value={this.state.cancellationPolicy}
              style={{ maxWidth: '100%' }}
              onChange={this.handleCancellationPolicyInputChange}
            />
            <Typography style={{
              alignSelf: "end",
              fontSize: '12px', fontWeight: 400, lineHeight: '18px'
            }}
              className="LeagueSpartan">{this.state.cancellationPolicy?.length || 0}/500</Typography>
          </Box>
        </Box>
        <Box paddingTop={"20px"}>
          <Divider />
        </Box>
        <Box display={"flex"} flexDirection={"column"} padding={"24px 40px"}>
          <Button
            variant="contained"
            style={webStyle.textAreaBtn}
            data-test-id="handleCancellationPolicy"
            onClick={this.handlePolicies}
          >
            Save
          </Button>
        </Box>
      </Paper>
    );
  };

  renderLocationInstruction = () => {
    return (
      <Paper elevation={2}>
        <Box paddingTop={"40px"} style={{ gap: "24px", display: "flex", flexDirection: "column" }}>
          <Box style={{ gap: "16px", display: "flex", flexDirection: "column" }}>
            <Typography style={{ ...webStyle.leftHeadings, padding: "0px 40px" }}
              className="LeagueSpartan">Location Instructions</Typography>
          </Box>

          <Box
            style={{
              minHeight: '300px',
              padding: '0px 40px',
              display: 'flex',
              flexDirection: 'column',
              maxWidth: '100%'
            }}
          >
            <TextareaAutosize
              className="textarea LeagueSpartan"
              minRows={8}
              data-test-id="handleLocationInstructionInputChange"
              value={this.state.locationInstruction}
              style={{ maxWidth: '100%' }}
              onChange={this.handleLocationInstructionInputChange}
            />

            <Typography style={{ alignSelf: "end", fontSize: '12px', fontWeight: 400, lineHeight: '18px' }}
              className="LeagueSpartan">{this.state.locationInstruction?.length || 0}/500</Typography>
          </Box>
        </Box>
        <Box paddingTop={"20px"}>
          <Divider />
        </Box>
        <Box display={"flex"} flexDirection={"column"} padding={"24px 40px"}>
          <Button
            variant="contained"
            style={webStyle.textAreaBtn}
            data-test-id="handleLocationInstruction"
            onClick={this.handlePolicies}
          >
            Save
          </Button>
        </Box>
      </Paper>
    );
  };

  UserManagement = () => {
    return (
      <Paper elevation={2}>
        <Box paddingY={"40px"} style={{ gap: "24px", display: "flex", flexDirection: "column" }}>
          <Box style={{ gap: "16px", display: "flex", flexDirection: "column" }}>
            <Typography style={{ ...webStyle.leftHeadings, padding: "0px 40px" }}
              className="LeagueSpartan">User management</Typography>
          </Box>
          {this.state.userListArray.length !== 0 ? (
            <Box paddingX={"40px"} overflow={"hidden"} display={"flex"} flexDirection={"column"} style={{ gap: "16px" }}>
              {this.state.userListArray.map((user, key) => (
                <Box
                  padding={"16px"}
                  borderRadius={"8px"}
                  border={"1px solid #CBD5E1"}
                  display={"flex"}
                  flexDirection={"row"}
                  style={{ gap: "16px", justifyContent: 'space-between' }}
                  key={key}
                >
                  <Box display={"flex"} flexDirection={"column"} width={"90%"} style={{ gap: '16px' }}>
                    <Typography className="LeagueSpartan"
                      style={{ color: '#1E293B', fontSize: '18px', fontWeight: 700, lineHeight: "26px" }}>
                      {user.fullName}
                    </Typography>
                    <Typography
                      className="LeagueSpartan"
                      style={{ fontSize: '14px', fontWeight: 400, lineHeight: "22px" }}
                    >
                      Username: {" "}
                      <span
                        className="LeagueSpartan"
                        style={{ color: '#1E293B', fontSize: '14px', fontWeight: 700, lineHeight: "22px" }}
                      >{user.username}</span>
                    </Typography>

                    <Typography
                      className="LeagueSpartan"
                      style={{ fontSize: '14px', fontWeight: 400, lineHeight: "22px" }}
                    >
                      Job Title: {" "}
                      <span
                        className="LeagueSpartan"
                        style={{ color: '#1E293B', fontSize: '14px', fontWeight: 700, lineHeight: "22px" }}
                      >{user.jobTitle}</span>
                    </Typography>

                    <Typography
                      className="LeagueSpartan"
                      style={{ fontSize: '14px', fontWeight: 400, lineHeight: "22px" }}
                    >
                      Phone Number: {" "}
                      <span
                        className="LeagueSpartan"
                        style={{ color: '#1E293B', fontSize: '14px', fontWeight: 700, lineHeight: "22px" }}
                      >{user.phoneNumber}</span>
                    </Typography>
                  </Box>
                  <Button style={{ alignSelf: "start", paddingLeft: '30px' }}
                    data-test-id={`menuBtn${key}`}
                    onClick={(event) => this.handleMenuOpen(event, key)}>
                    <MoreVert />
                  </Button>
                </Box>
              ))}
            </Box>
          ) : (
            <Box
              style={{
                padding: '40px',
                display: 'flex',
                gap: '24px',
                flexDirection: 'column',
                maxWidth: '100%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img src={ic_userManagement} height={"240px"} width={"240px"} />
              <Box textAlign={"center"}>
                <Typography className="LeagueSpartan" style={{ fontWeight: 400, fontSize: '20px', lineHeight: '28px' }}>
                  There are no users currently added.
                  <br />Click 'Add User' to get started.
                </Typography>
              </Box>
              <Button
                variant="contained"
                style={{ ...webStyle.textAreaBtn, alignSelf: 'center' }}
                data-test-id="handleAddUserDialog"
                onClick={this.handleAddUserDialog}
              >
                Add User
              </Button>
            </Box>
          )}

          <Menu
            anchorEl={this.state.anchorEl}
            open={Boolean(this.state.anchorEl)}
            onClose={this.handleMenuClose}
          >
            <MenuItem data-test-id="editBtn"
              style={{ display: 'flex', padding: "12px 16px", gap: '8px' }}
              onClick={this.handleEditUser}>
              <Edit style={{ color: "#64748B" }} />
              <Box>
                <Typography className="LeagueSpartan">
                  Edit
                </Typography>
              </Box>
            </MenuItem>
            <MenuItem data-test-id="deleteBtn" onClick={this.handleDeleteUser}
              style={{ display: 'flex', color: '#DC2626', justifyContent: 'space-between', padding: "12px 16px", gap: '8px' }}
            >
              <DeleteOutline />
              <Box>
                <Typography className="LeagueSpartan">
                  Delete user
                </Typography>
              </Box>
            </MenuItem>
          </Menu>
        </Box>
      </Paper>
    )
  }

  renderBusiness = () => {
    const { businessActiveField } = this.state;

    switch (businessActiveField) {
      case 'Business profile':
        return (
          this.BusinessProfile()
        );
      case 'Legal':
        return (
          this.LegalSettings()
        );
      case 'User management':
        return (
          this.UserManagement()
        );

    }

  }

  renderProfile = () => {
    const { profileActiveField } = this.state
    switch (profileActiveField) {
      case 'Account settings':
        return (
          <>
            {this.renderAccountSettings()}
          </>
        )
      case 'Language':
        return (
          <>
            {this.renderLanguage()}
          </>
        )
      case 'Notification':
        return (
          <>
            {this.renderNotifications()}
          </>
        )
    }
  }

  renderAccountSettings = () => {
    return (
      <Paper>
        <Box display={"flex"} flexDirection={"column"} style={{ gap: "16px", minHeight: '540px' }} padding={"0px 40px 24px 40px"}>
          <Typography

            className="LeagueSpartan"
            style={{ fontWeight: 700, fontSize: '20px', color: '#334155', lineHeight: '28px', paddingTop: '40px' }}
          >
            Account Settings
          </Typography>

          <Grid container spacing={2} >
            <Grid item xs={12} sm={6}>
              <Typography
                style={webStyle.BusinessSettingsSubHeadingTypography}
                className="LeagueSpartan">
                First name
              </Typography>
              <TextField
                variant="outlined"
                type="text"
                fullWidth
                data-test-id="handleFirstName"
                onChange={this.handleFirstName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                style={webStyle.BusinessSettingsSubHeadingTypography}
                className="LeagueSpartan">
                Second name
              </Typography>
              <TextField
                variant="outlined"
                type="text"
                data-test-id="handleLastName"
                fullWidth
                onChange={this.handleLastName}
              />

            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                style={webStyle.BusinessSettingsSubHeadingTypography}
                className="LeagueSpartan">
                Email address
              </Typography>
              <TextField
                disabled
                value={this.state.email}
                fullWidth
                className="emailDialog"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton className="emailDialogBtn" onClick={this.openEmailDialogBox}>
                        <Edit style={{ color: "#FAA81D" }} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography
                style={webStyle.BusinessSettingsSubHeadingTypography}
                className="LeagueSpartan">
                Password
              </Typography>
              <TextField
                fullWidth
                type="password"
                value="********"
                className="passDialog"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        className="passDialogBtn"
                        onClick={this.openPasswordDialogBox}>
                        <Edit style={{ color: "#FAA81D" }} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
          </Grid>

          <Box data-test-id="deleteBtn" onClick={this.handleDeleteAccount}
            style={{ display: 'flex', color: '#DC2626', gap: '8px' }}
          >
            <DeleteOutline />
            <Box>
              <Typography className="LeagueSpartan" style={{ fontSize: '18px', lineHeight: '26px', fontWeight: 700, }}>
                Delete account
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box
          display={'flex'} padding={'20px'} justifyContent={'end'}>
          <Button
            onClick={this.handleAccountSettings}
            data-test-id="handleAccountSettings"
            style={webStyle.saveBtn}
          >
            SAVE
          </Button>
        </Box>
      </Paper>
    )
  }

  renderLanguage = () => {
    return (
      <Paper>
        <Box display={"flex"} flexDirection={"column"} style={{ gap: "16px", minHeight: '540px' }} padding={"0px 40px 24px 40px"}>
          <Typography
            className="LeagueSpartan"
            style={{ fontWeight: 700, fontSize: '20px', color: '#334155', lineHeight: '28px', paddingTop: '40px' }}
          >
            Language
          </Typography>
          <RadioGroup
            value={this.state.language}
            onChange={this.handleLanguageChange}
            data-test-id="handleLanguageChange"
            style={{ display: 'flex', width: "100%", gap: '16px', flexDirection: "column", borderRadius: '10px', }}
          >
            <FormControlLabel
              style={webStyle.FormControlCheckLanguage}
              labelPlacement="start"
              label={
                <Typography style={webStyle.languageTypography} className="LeagueSpartan">
                  English</Typography>
              }
              value="english"
              control={
                <Radio
                  style={webStyle.radioBtn}
                  disableRipple
                  checkedIcon={<BpCheckedIcon />}
                  icon={<BpIcon />}
                />
              }
            />
            <FormControlLabel
              labelPlacement="start"
              style={webStyle.FormControlCheckLanguage}
              label={
                <Typography style={webStyle.languageTypography}>
                  Spanish</Typography>
              }
              value="spanish"
              control={
                <Radio
                  style={webStyle.radioBtn}
                  disableRipple
                  checkedIcon={<BpCheckedIcon />}
                  icon={<BpIcon />}
                />
              }
            />
            <FormControlLabel
              style={webStyle.FormControlCheckLanguage}
              labelPlacement="start"
              label={
                <Typography style={webStyle.languageTypography}>
                  Icelandic</Typography>
              }
              value="icelandic"
              control={
                <Radio
                  style={webStyle.radioBtn}
                  disableRipple
                  checkedIcon={<BpCheckedIcon />}
                  icon={<BpIcon />}
                />
              }
            />
          </RadioGroup>
        </Box>
        <Divider />
        <Box
          display={'flex'} padding={'20px'} justifyContent={'end'}>
          <Button
            onClick={this.handleSettings}
            data-test-id="handleSettings"
            style={webStyle.saveBtn}
          >
            SAVE
          </Button>
        </Box>
      </Paper>

    )
  }

  renderNotifications = () => {
    return (
      <Paper>
        <Box display={"flex"} flexDirection={"column"} style={{ gap: "16px", minHeight: '540px' }} padding={"0px 40px 24px 40px"}>
          <Typography
            style={{ fontWeight: 700, fontSize: '20px', color: '#334155', lineHeight: '28px', paddingTop: '40px' }}
            className="LeagueSpartan"
          >
            Notification
          </Typography>
          <Box
            style={{ display: 'flex', width: "100%", gap: '16px', padding: '12px 16px', flexDirection: "column", borderRadius: '10px', }}
          >
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography style={webStyle.languageTypography}>Allow push notifications to this device</Typography>
              <SwitchBtn
                checked={this.state.allowPushNotificationDevice}
                data-test-id="handleAllowPushNotificationDevice"
                onChange={this.handleAllowPushNotificationDevice}
              />
            </Box>
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography style={webStyle.languageTypography}>Push notifications</Typography>
              <SwitchBtn
                checked={this.state.allowPushNotification}
                onChange={this.handleAllowPushNotification}
                data-test-id="handleAllowPushNotification"
              />
            </Box>
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography style={webStyle.languageTypography}>Email</Typography>
              <SwitchBtn
                checked={this.state.allowEmail}
                onChange={this.handleAllowEmail}
                data-test-id="handleAllowEmail"
              />
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box
          display={'flex'} padding={'20px'} justifyContent={'end'}>
          <Button
            onClick={this.handleSettings}
            data-test-id="handleSettings"
            style={webStyle.saveBtn}
          >
            SAVE
          </Button>
        </Box>
      </Paper>
    )
  }

  AddUserForm = () => {
    const { selectedUser } = this.state
    return (
      <Formik
        initialValues={
          selectedUser ? {
            fullName: selectedUser.fullName,
            userRole: selectedUser.userRole,
            jobTitle: selectedUser.jobTitle,
            email: selectedUser.email,
            phoneNumber: selectedUser.phoneNumber,
            password: selectedUser.password,
            username: selectedUser.username,
            accessPermission: selectedUser.accessPermission,
          } :
            {
              fullName: "",
              userRole: "",
              jobTitle: "",
              email: "",
              phoneNumber: "",
              username: "",
              password: "",
              accessPermission: "",
            }
        }
        validationSchema={addUserSchema}
        onSubmit={this.handleSaveUser}
        enableReinitialize={true}
      >
        {({ handleSubmit, errors, values }) => (
          <Form onSubmit={handleSubmit} style={{ margin: "0px" }}>
            <Box paddingTop={"10px"} style={{ gap: "24px", display: "flex", flexDirection: "column" }}>
              <Box style={{ gap: "16px", display: "flex", flexDirection: "column" }}>
                <Box padding={"0px 40px"} display={"flex"} flexDirection={"column"}>
                  <Typography style={webStyle.BusinessSettingsSubHeadingTypography} className="LeagueSpartan">Full Name</Typography>
                  <Field
                    as={TextField}
                    name="fullName"
                    value={values.fullName}
                    variant="outlined"
                    type="text"
                  />
                  {errors.fullName && <Typography color="error" style={webStyle.errorText}>{errors.fullName}</Typography>}
                </Box>
                <Grid container spacing={2} style={{ padding: "0px 40px" }}>
                  <Grid item xs={12} sm={6}>
                    <Typography style={webStyle.BusinessSettingsSubHeadingTypography} className="LeagueSpartan">User Role</Typography>
                    <Field
                      as={TextField}
                      name="userRole"
                      variant="outlined"
                      type="text"
                      fullWidth
                    />
                    {errors.userRole && <Typography color="error" style={webStyle.errorText}>{errors.userRole}</Typography>}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography style={webStyle.BusinessSettingsSubHeadingTypography} className="LeagueSpartan">Job Title</Typography>
                    <Field
                      as={TextField}
                      name="jobTitle"
                      variant="outlined"
                      fullWidth
                    />
                    {errors.jobTitle && <Typography color="error" style={webStyle.errorText}>{errors.jobTitle}</Typography>}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography style={webStyle.BusinessSettingsSubHeadingTypography} className="LeagueSpartan">Email Address</Typography>
                    <Field
                      as={TextField}
                      name="email"
                      variant="outlined"
                      fullWidth
                    />
                    {errors.email && <Typography color="error" style={webStyle.errorText}>{errors.email}</Typography>}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography style={webStyle.BusinessSettingsSubHeadingTypography} className="LeagueSpartan">Phone Number</Typography>
                    <Field
                      as={TextField}
                      name="phoneNumber"
                      variant="outlined"
                      fullWidth
                    />
                    {errors.phoneNumber && <Typography color="error" style={webStyle.errorText}>{errors.phoneNumber}</Typography>}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography style={webStyle.BusinessSettingsSubHeadingTypography} className="LeagueSpartan">Username</Typography>
                    <Field
                      as={TextField}
                      name="username"
                      variant="outlined"
                      fullWidth
                    />
                    {errors.username && <Typography color="error" style={webStyle.errorText}>{errors.username}</Typography>}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography style={webStyle.BusinessSettingsSubHeadingTypography} className="LeagueSpartan">Password</Typography>
                    <Field
                      as={TextField}
                      name="password"
                      variant="outlined"
                      type="password"
                      fullWidth
                    />
                    {errors.password && <Typography color="error" style={webStyle.errorText} >{errors.password}</Typography>}
                  </Grid>
                </Grid>
                <Box display={"flex"} flexDirection={"column"} paddingX={"40px"}>
                  <Typography style={webStyle.BusinessSettingsSubHeadingTypography} className="LeagueSpartan">Access Permissions</Typography>
                  <Field name="accessPermission">
                    {({ field }: any) => (
                      <RadioGroup {...field} data-test-id="accessPermission" style={{ display: 'flex', width: "100%", gap: '16px', flexDirection: "column", borderRadius: '10px' }}>
                        <FormControlLabel
                          style={webStyle.FormControlCheck}
                          labelPlacement="end"
                          label={
                            <Typography style={webStyle.accessPermissionTypography} className="LeagueSpartan">
                              Can view</Typography>
                          }
                          value="view"
                          name="accessPermission"
                          control={
                            <Radio
                              style={webStyle.radioBtn}
                              disableRipple
                              checkedIcon={<BpCheckedIcon />}
                              icon={<BpIcon />}
                            />
                          }
                        />
                        <FormControlLabel
                          style={webStyle.FormControlCheck}
                          labelPlacement="end"
                          label={
                            <Typography style={webStyle.accessPermissionTypography}>
                              Can edit</Typography>
                          }
                          value="edit"
                          name="accessPermission"
                          control={
                            <Radio
                              style={webStyle.radioBtn}
                              disableRipple
                              checkedIcon={<BpCheckedIcon />}
                              icon={<BpIcon />}
                            />
                          }
                        />
                        <FormControlLabel
                          style={webStyle.FormControlCheck}
                          labelPlacement="end"
                          label={
                            <Typography style={webStyle.accessPermissionTypography}>
                              Can manage within the system</Typography>
                          }
                          value="system"
                          name="accessPermission"
                          control={
                            <Radio
                              style={webStyle.radioBtn}
                              disableRipple
                              checkedIcon={<BpCheckedIcon />}
                              icon={<BpIcon />}
                            />
                          }
                        />
                      </RadioGroup>
                    )}
                  </Field>
                </Box>
              </Box>
            </Box>

            <Box paddingTop={"20px"}>
              <Divider />
            </Box>

            <Box style={{ gap: '16px' }}
              display={'flex'} padding={'24px'} justifyContent={'end'} >
              <Button
                onClick={this.closeAddUserDialog}
                data-test-id="closeAddUserDialog"
                style={{
                  borderRadius: '8px',
                  color: '#FAA81D',
                  border: '#FAA81D 1px solid',
                  fontWeight: 700, fontSize: '16px',
                  padding: '16px 26px',
                  lineHeight: '24px'
                }}
              >
                CANCEL
              </Button>
              <Button
                data-test-id="handleLogout"
                type="submit"
                style={{
                  color: '#fff',
                  backgroundColor: '#FAA81D',
                  fontWeight: 700,
                  padding: '16px 24px',
                  fontSize: '16px', lineHeight: '24px',
                  borderRadius: '8px',
                }}
              >
                SAVE
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    )
  }

  AddUserDialogBox = () => {
    return (
      <Dialog open={this.state.addUserDialogBox}>
        <style>
          {`
            .MuiDialog-paperWidthSm {
                max-width: 800px;
              }
            .MuiPaper-rounded {
              border-radius:8px 8px 32px 8px
            }
            .MuiDialog-paperScrollPaper {
              max-height: 800px;
            }
        `}
        </style>
        <DialogContent
          style={{
            padding: "10px 0px 0px 0px",
            overflow: 'hidden'
          }}
        >
          <Box display={"flex"}
            flexDirection={"column"} >
            <Box display={"flex"}
              flexDirection={"row"} justifyContent={"space-between"} padding={"16px 0px 16px 40px"}>
              <Typography
                className="LeagueSpartan"
                style={{
                  fontWeight: 700,
                  color: '#0F172A',
                  fontSize: '24px',
                  lineHeight: '32px',
                  letterSpacing: '-0.5'
                }}>
                Add new user
              </Typography>
              <Box alignSelf={"end"}>
                <Button
                  data-test-id="closeAddUserDialog"
                  onClick={this.closeAddUserDialog}
                >
                  <Clear />
                </Button>
              </Box>
            </Box>

            <Divider />

            {this.AddUserForm()}
          </Box>
        </DialogContent>
      </Dialog>
    )
  }

  EmailDialogBox = () => {
    return (
      <Dialog open={this.state.emailDialogBox}>
        <style>
          {`
            .MuiPaper-rounded {
              border-radius:8px 8px 32px 8px
            }
            .MuiDialog-paperWidthSm {
              max-width: 800px;
            }
            .MuiDialog-paperScrollPaper {
              max-height: 800px;
            }
        `}
        </style>
        <DialogContent
          style={{ padding: "10px 0px 0px 0px", overflow: 'hidden', width: '600px' }}
        >

          <Formik
            initialValues={{ email: '', confirmEmail: '', password: '' }}
            validationSchema={emailValidationSchema}
            onSubmit={
              this.handleEmailChange
            }
          >
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Box display={"flex"} flexDirection={"column"} >
                  <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"} padding={"16px 0px 16px 40px"}>
                    <Typography
                      className="LeagueSpartan"
                      style={{
                        color: '#0F172A',
                        fontWeight: 700,
                        fontSize: '24px',
                        lineHeight: '32px',
                        letterSpacing: '-0.5'
                      }}>
                      Change email
                    </Typography>
                    <Box alignSelf={"end"}>
                      <Button
                        data-test-id="closeEmailDialogBox"
                        onClick={this.closeEmailDialogBox}
                      >
                        <Clear />
                      </Button>
                    </Box>
                  </Box>

                  <Divider />


                  <Box display="flex" flexDirection="column" paddingY="20px" style={{ gap: '10px' }}>
                    <Box padding="0px 40px" display="flex" flexDirection="column">
                      <Typography style={webStyle.BusinessSettingsSubHeadingTypography} className="LeagueSpartan">
                        New email address
                      </Typography>
                      <Field
                        as={TextField}
                        variant="outlined"
                        type="email"
                        name="email"
                        fullWidth
                      />
                      <ErrorMessage name="email" component="div" style={{ color: 'red' }} />
                    </Box>

                    <Box padding="0px 40px" display="flex" flexDirection="column">
                      <Typography style={webStyle.BusinessSettingsSubHeadingTypography} className="LeagueSpartan">
                        Confirm email address
                      </Typography>
                      <Field
                        as={TextField}
                        variant="outlined"
                        type="email"
                        name="confirmEmail"
                        fullWidth
                      />
                      <ErrorMessage name="confirmEmail" component="div" style={{ color: 'red' }} />
                    </Box>

                    <Box padding="0px 40px" display="flex" flexDirection="column">
                      <Typography style={webStyle.BusinessSettingsSubHeadingTypography} className="LeagueSpartan">
                        Confirm password
                      </Typography>
                      <Field
                        as={TextField}
                        variant="outlined"
                        name="password"
                        fullWidth
                        type={this.state.showOldPassword ? 'text' : 'password'}
                        InputProps={{
                          style: { backgroundColor: 'white', padding: '2' },
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => this.handleToggle(1)}
                                className="password_button_type"
                                aria-label="toggle password visibility"
                              >
                                {!this.state.showOldPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <ErrorMessage name="password" component="div" style={{ color: 'red' }} />
                    </Box>
                  </Box>
                  <Box paddingTop={"20px"}>
                    <Divider />
                  </Box>

                  <Box style={{ gap: '16px' }}
                    display={'flex'} padding={'24px'} justifyContent={'end'} >
                    <Button
                      onClick={this.closeEmailDialogBox}
                      data-test-id="closeEmailDialogBox"
                      style={{
                        borderRadius: '8px',
                        color: '#FAA81D',
                        border: '#FAA81D 1px solid',
                        fontWeight: 700, fontSize: '16px',
                        padding: '16px 26px',
                        lineHeight: '24px'
                      }}
                    >
                      CANCEL
                    </Button>
                    <Button
                      type="submit"
                      style={{
                        color: '#fff',
                        backgroundColor: '#FAA81D',
                        fontWeight: 700,
                        padding: '16px 24px',
                        fontSize: '16px', lineHeight: '24px',
                        borderRadius: '8px',
                      }}
                    >
                      SAVE
                    </Button>
                  </Box>


                </Box>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    )
  }

  UpdateEmailDialogBox = () => {
    return (

      <Dialog open={this.state.showUpdateEmailDialogBox}>
        <style>
          {`
           .MuiPaper-rounded{
             border-radius:8px 8px 32px 8px
           }
        `}
        </style>
        <DialogContent
          style={{
            height: "280px", padding: "10px 0px", width: "600px",
          }}>
          <Box
            display={"flex"}
            flexDirection={"column"}
          >
            <Box alignSelf={"end"}>
              <Button
                onClick={this.closeUpdateEmailDialogBox}
                data-test-id="closeUpdateEmailDialogBox"
              >
                <Clear />
              </Button>
            </Box>
            <Box padding={'25'} style={{
              display: "flex",
              gap: "10px",
              flexDirection: "column"
            }}>
              <Typography
                className="LeagueSpartan"
                style={{
                  color: '#0F172A',
                  fontWeight: 700,
                  fontSize: '20px',
                  lineHeight: '32px'
                }}
              >Your email address has been updated!</Typography>
              <Typography className="LeagueSpartan" style={{ color: '#0F172A', fontWeight: 400, fontSize: '15px', lineHeight: '26px' }}>We sent you a verification code to confirm your new email. Please <br /> open this link to verify the change</Typography>
            </Box>
            <Box style={{ gap: '10px' }} display={'flex'} padding={'24px 24px 0px 0px'} justifyContent={'end'} borderTop={"1px #CBD5E1 solid"} >
              <Button
                onClick={this.closeUpdateEmailDialogBox}
                data-test-id="closeUpdateEmailDialogBox"
                style={{
                  color: '#FAA81D',
                  border: '#FAA81D 1px solid',
                  fontWeight: 700, fontSize: '16px',
                  borderRadius: '8px',
                  padding: '16px 26px',
                  lineHeight: '24px'
                }}
              >
                CANCEL
              </Button>
              <Button
                onClick={this.handleEmailDialogContinue}
                data-test-id="handleEmailDialogContinue"
                style={{
                  color: '#fff',
                  backgroundColor: '#FAA81D',
                  borderRadius: '8px',
                  padding: '16px',
                  fontWeight: 700,
                  fontSize: '16px', lineHeight: '24px'
                }}
              >
                OPEN EMAIL
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    )
  }

  PasswordDialogBox = () => {
    return (
      <Dialog open={this.state.passwordDialogBox}>
        <style>
          {`
                .MuiDialog-paperScrollPaper {
                    max-height: 800px;
                }
                .MuiPaper-rounded {
                  border-radius:8px 8px 32px 8px
                }
                .MuiDialog-paperWidthSm {
                  max-width: 800px;
                }
            `}
        </style>

        <Formik
          initialValues={{ old_password: '', new_password: '', confirm_password: '' }}
          validationSchema={passwordValidationSchema}
          onSubmit={this.handlePasswordChange}
          validateOnMount={true}
        >
          {({ values, handleChange, isValid, }) => (
            <Form
              style={{ margin: '0px', padding: '0px' }}
            >
              <DialogContent
                style={{ padding: "10px 0px 0px 0px", overflow: 'hidden', width: '600px' }}
              >
                <Box display={"flex"} flexDirection={"column"} >
                  <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"} padding={"16px 0px 16px 40px"}>
                    <Typography
                      className="LeagueSpartan"
                      style={{
                        color: '#0F172A',
                        fontWeight: 700,
                        fontSize: '24px',
                        lineHeight: '32px',
                        letterSpacing: '-0.5'
                      }}>
                      Change password
                    </Typography>
                    <Box alignSelf={"end"}>
                      <Button
                        className="closePasswordDialogBox"
                        data-test-id="closePasswordDialogBox"
                        onClick={this.closePasswordDialogBox}
                      >
                        <Clear />
                      </Button>
                    </Box>
                  </Box>

                  <Divider />
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    width={'100%'}
                    style={{ padding: '20px 0px', justifyContent: 'space-between', gap: '15px' }}
                  >
                    <Box padding={"10px 20px"} style={{
                      gap: "16px",
                      display: 'flex',
                      flexDirection: "column"
                    }}>
                      <Box>
                        <Typography className="LeagueSpartan" style={webStyle.BusinessSettingsSubHeadingTypography}>Old password</Typography>
                        <Field
                          as={TextField}
                          variant="outlined"
                          name="old_password"
                          type={this.state.showOldPassword ? 'text' : 'password'}
                          value={values.old_password}
                          fullWidth
                          inputProps={{ style: { backgroundColor: "white" } }}
                          className="login_test_password"
                          onChange={handleChange}
                          InputProps={{
                            style: { backgroundColor: 'white', padding: '2' },
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => this.handleToggle(1)}
                                  className="password_button_type"
                                  aria-label="toggle password visibility"
                                >
                                  {!this.state.showOldPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          placeholder="********"
                        />
                        <ErrorMessage name="old_password" component="div" style={{ color: 'red', fontSize: '10px' }} />
                      </Box>
                      <Box>
                        <Typography className="LeagueSpartan" style={webStyle.BusinessSettingsSubHeadingTypography}>New password</Typography>
                        <Field
                          as={TextField}
                          variant="outlined"
                          name="new_password"
                          fullWidth
                          type={this.state.showNewPassword ? 'text' : 'password'}
                          value={values.new_password}
                          onChange={handleChange}
                          className="login_test_password2"
                          inputProps={{ style: { backgroundColor: "white" } }}
                          InputProps={{
                            style: { backgroundColor: 'white', padding: '2' },
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() => this.handleToggle(2)}
                                  className="password_button_type2"
                                >
                                  {!this.state.showNewPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          placeholder="********"
                        />
                        <ErrorMessage name="new_password" component="div" style={{ color: 'red', fontSize: '10px' }} />
                      </Box>
                      <Box>
                        <Typography className="LeagueSpartan" style={webStyle.BusinessSettingsSubHeadingTypography}>Confirm password</Typography>
                        <Field
                          as={TextField}
                          variant="outlined"
                          type={this.state.showConfirmPassword ? 'text' : 'password'}
                          fullWidth
                          name="confirm_password"
                          value={values.confirm_password}
                          className="login_test_password3"
                          onChange={handleChange}
                          inputProps={{ style: { backgroundColor: "white" } }}
                          InputProps={{
                            style: { backgroundColor: 'white', padding: '2' },
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={
                                    () => this.handleToggle(3)
                                  }
                                  className="password_button_type3"
                                >
                                  {!this.state.showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          placeholder="********"
                        />
                        <ErrorMessage name="confirm_password" component="div" style={{ color: 'red', fontSize: '10px' }} />
                      </Box>
                    </Box>
                  </Box>

                  <Box paddingTop={"20px"}>
                    <Divider />
                  </Box>

                  <Box style={{ gap: '16px' }}
                    display={'flex'} padding={'24px'} justifyContent={'end'} >
                    <Button
                      onClick={this.closePasswordDialogBox}
                      data-test-id="closePasswordDialogBox"
                      style={{
                        borderRadius: '8px',
                        color: '#FAA81D',
                        border: '#FAA81D 1px solid',
                        fontWeight: 700, fontSize: '16px',
                        padding: '16px 26px',
                        lineHeight: '24px'
                      }}
                    >
                      CANCEL
                    </Button>
                    <Button
                      style={{
                        color: isValid ? '#fff' : '#64748B',
                        backgroundColor: isValid ? '#FAA81D' : '#F1F5F9',
                        borderRadius: '8px', fontWeight: 700,
                        fontSize: '16px', lineHeight: '24px',
                        padding: '16px 24px'
                      }}
                      type="submit"
                      disabled={!isValid}
                    >
                      CHANGE PASSWORD
                    </Button>
                  </Box>
                </Box>
              </DialogContent>
            </Form>
          )}
        </Formik>
      </Dialog>
    )
  };

  HotelSettingsMain = () => {
    return (

      <Box display={'flex'} flexDirection={'row'} height={'100%'}
        style={{
          fontFamily: 'League Spartan , sans-serif'
        }}>
        <Box width={'120px'}>
          <HotelNavigationMenu navigation={undefined} id={""} />
        </Box>
        <Box display={'flex'} flexDirection={'column'} width={'100%'} height={'100%'} style={{
          backgroundImage: `url(${ic_background})`,
          backgroundRepeat: "no-repeat", backgroundSize: 'cover',
        }}>

          <Box display={'flex'} justifyContent={"space-between"} padding={'20px 40px'} style={{ borderLeft: "1px #CBD5E1 solid" }}>
            <Typography variant="h5" className="LeagueSpartan" style={{ fontWeight: 700, fontSize: '24px', color: "#0F172A" }}>Settings</Typography>
            <Box>
              {this.state.businessActiveField === 'User management' && this.state.userListArray.length !== 0 &&
                <Button
                  style={{
                    fontWeight: 700, fontStyle: 'bold', alignSelf: 'start', fontSize: '18px', lineHeight: '26px', backgroundColor: "#FAA81D", color: '#fff',
                    textTransform: 'none', padding: '10px 16px 10px 12px'
                  }}
                  startIcon={<AddCircleOutline style={{ height: '24px', width: '24px', backgroundColor: "#FAA81D", color: '#fff' }} />}
                  data-test-id="handleAddUserDialog2"
                  className="LeagueSpartan"
                  onClick={this.handleAddUserDialog}
                >
                  ADD USER
                </Button>
              }<Button><img src={ic_notifications} alt="" /></Button>
            </Box>
          </Box>

          <Box display={'flex'} flexDirection={'row'} >

            <Box width={"360px"} style={{ backgroundColor: '#fff', borderLeft: "1px #CBD5E1 solid", minHeight: '100vh' }} padding={"10px 15px 0px 10px"}  >
              <Box padding={'10px'} style={{
                display: "flex",
                flexDirection: "column",
                gap: "30px",
              }}>
                <Box display={"flex"} width={'310px'} borderRadius={'8px'} padding={"16px 20px"}
                  style={{ backgroundColor: this.state.BusinessSettings ? '#FEF3C7' : '#fff', gap: "10px" }}
                >
                  <img height={"24px"} width={"24px"} src={ic_business} alt="" />
                  <Box display={"flex"} flexDirection={"column"}>
                    <Typography variant="h6" style={webStyle.leftHeadings} className="LeagueSpartan">Business Settings</Typography>
                    <Box style={{ padding: "10px 0px 0px 4px" }}>
                      {BusinessSettingsfields.map((field, index) => (
                        <Typography
                          onClick={() => this.handleBusinessFieldClick(field)}
                          key={index}
                          data-test-id={`Businesscase${index}`}
                          className="LeagueSpartan"
                          style={
                            this.state.businessActiveField === field ? webStyle.FieldActive : webStyle.Fields}
                        >
                          {field}
                        </Typography>
                      ))}
                    </Box>
                  </Box>
                </Box>
                <Box display={"flex"} width={'310px'} borderRadius={'8px'} padding={"16px 20px"}
                  style={{ backgroundColor: this.state.ProfileSettings ? '#FEF3C7' : '#fff', gap: "10px" }}
                >
                  <img height={"24px"} width={"24px"} src={ic_profile} alt="" />
                  <Box display={"flex"} flexDirection={"column"}>
                    <Typography variant="h6" style={webStyle.leftHeadings} className="LeagueSpartan">Personal Settings</Typography>
                    <Box style={{ padding: "10px 0px 0px 4px" }}>
                      {ProfileSettingsfields.map((field, index) => (
                        <Typography
                          onClick={() => this.handleProfileFieldClick(field)}
                          key={index}
                          data-test-id={`Profilecase${index}`}
                          className="LeagueSpartan"
                          style={
                            this.state.profileActiveField === field ? webStyle.FieldActive : webStyle.Fields}
                        >
                          {field}
                        </Typography>
                      ))}
                    </Box>
                  </Box>
                </Box>

                <Box style={webStyle.list}
                  data-test-id="logoutBox" >
                  <img height={"24px"} width={"24px"} src={ic_logout} alt="" />
                  <Typography variant="h6" style={{ color: '#DC2626', fontWeight: 700, fontSize: '20px', lineHeight: '28px' }} className="LeagueSpartan">Log out</Typography>
                </Box>

              </Box>
            </Box>

            <Box style={{
              padding: "20px 40px",
              display: "flex",
              flexDirection: "column",
              gap: "24px",
              minWidth: '72%',
              maxWidth: '72%',
            }}>
              {this.state.BusinessSettings &&
                <>
                  {this.state.businessActiveField === "Business profile" &&
                    <>
                      <style>{`
                         .MuiTabs-flexContainer{
                           justify-content:space-between;
                         }
                         .MuiTab-root{
                           min-width:33%
                         }
                         `}
                      </style>
                      <CustomTabs
                        value={this.state.BusinessSettingsFields}
                        onChange={this.handleBusinessTabChange}
                        data-test-id="tabs1"
                      >
                        <CustomTab data-test-id="GBI"
                          label={
                            <Typography className="LeagueSpartan"
                              style={{ fontWeight: 700, fontSize: '16px', lineHeight: '24px', alignSelf: 'center' }}>
                              General Business Information
                            </Typography>
                          }
                          value="General Business Information" />
                        <CustomTab data-test-id="PBI"
                          label={
                            <Typography className="LeagueSpartan"
                              style={{ fontWeight: 700, fontSize: '16px', lineHeight: '24px', alignSelf: 'center' }}>
                              Payment and Banking Information
                            </Typography>
                          }
                          value="Payment and Banking Information" />
                        <CustomTab data-test-id="AUI"
                          label={
                            <Typography className="LeagueSpartan"
                              style={{ fontWeight: 700, fontSize: '16px', lineHeight: '24px', alignSelf: 'center' }}>
                              About Us Information
                            </Typography>
                          }
                          value="About Us Information" />
                      </CustomTabs>
                    </>
                  }
                  {this.state.businessActiveField === "Legal" &&
                    <>
                      <style>{`
                         .MuiTabs-flexContainer{
                           justify-content:space-between;
                         }
                         .MuiTab-root{
                           min-width:25%
                         }
                     `}
                      </style>
                      <CustomTabs
                        value={this.state.LegalSettingsField}
                        onChange={this.handleLegalTabChange}
                        data-test-id="tabs2"
                      >
                        <CustomTab data-test-id="GBI"
                          label={
                            <Typography className="LeagueSpartan"
                              style={{ fontWeight: 700, fontSize: '16px', lineHeight: '24px', alignSelf: 'center' }}>
                              Terms and Conditions
                            </Typography>
                          }
                          value="Terms and Conditions" />
                        <CustomTab data-test-id="PBI"
                          label={
                            <Typography className="LeagueSpartan"
                              style={{ fontWeight: 700, fontSize: '16px', lineHeight: '24px', alignSelf: 'center' }}>
                              Payment Policy
                            </Typography>
                          }
                          value="Payment Policy" />
                        <CustomTab data-test-id="AUI"
                          label={
                            <Typography className="LeagueSpartan"
                              style={{ fontWeight: 700, fontSize: '16px', lineHeight: '24px', alignSelf: 'center' }}>
                              Cancellation Policy
                            </Typography>
                          }
                          value="Cancellation Policy" />

                        <CustomTab data-test-id="AUI"
                          label={
                            <Typography className="LeagueSpartan"
                              style={{ fontWeight: 700, fontSize: '16px', lineHeight: '24px', alignSelf: 'center' }}>
                              Location Instructions
                            </Typography>
                          }
                          value="Location Instructions" />
                      </CustomTabs>
                    </>

                  }
                  {this.renderBusiness()}
                </>
              }
              {this.state.ProfileSettings && this.renderProfile()}
            </Box>
          </Box>

        </Box>
      </Box>
    )
  }

  Otp = () => {
    return (
      <Box display={'flex'} flexDirection={'column'} width={'100%'} height={'100%'}
        style={{
          backgroundImage: `url(${ic_background})`,
          backgroundRepeat: "no-repeat", backgroundSize: 'cover',
        }}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Paper style={{ height: '350px', display: 'flex', padding: '20px', justifyContent: "center", alignItems: "center" }} elevation={2}>

          <Box sx={webStyle.form}>
            <Box display={"flex"}>
              <Typography variant="h5" style={{ ...webStyle.formHeading, paddingBottom: '20px' }}>Verification Code</Typography>
            </Box>
            <Box
              style={{
                display: 'flex', flexDirection: 'column', textAlign: 'left',

              }}>
              <Typography
                style={webStyle.instructions}
                className="LeagueSpartan"
              >
                Enter Verification Code</Typography>
              <Box>
                <OTPInput
                  containerStyle={{ justifyContent: 'space-between' }}
                  value={this.state.otp}
                  onChange={this.setOtp}
                  data-test-id='input1'
                  numInputs={4}
                  inputType="tel"
                  renderInput={(props) => <input {...props} />}
                  inputStyle={{ ...webStyle.otpInput }}
                />
              </Box>

              <Box display={"flex"} paddingY={"20px"} flexDirection={"column"}>
                <Button
                  onClick={this.handleEmailOtpVerification}
                  data-test-id='verifyBtn'
                  fullWidth
                  style={{ ...webStyle.verifyButton, alignSelf: 'end' }}>SEND</Button>
              </Box>
              <Typography align="center" variant="body2" style={{
                ...webStyle.resendText,
              }}
                className="LeagueSpartan"
              >
                Don’t receive the email?
                <span style={webStyle.resendLink}
                  className="LeagueSpartan"
                  data-test-id="reverifyBtn" onClick={this.reVerify}>Click to resend</span>
              </Typography>
            </Box>
          </Box>
        </Paper>

      </Box>
    )
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start

      <ThemeProvider theme={theme}>
        <style>
          {`
          .textarea {
            max-width: 100%;
            min-width: 100%;
            min-height: 60px;
            border-radius: 8px;
            border: 1px solid #CBD5E1;
            padding: 14px;
            font-size: 16px;
            font-weight: 400;
            color: #0F172A; 
          }
          .textarea:focus {
            border: 1px solid #625d58;
            outline: none;
          }
          `}
        </style>
        {this.state.showOtpSection ?
          this.Otp()
          :
          this.HotelSettingsMain()
        }

        {this.AddUserDialogBox()}

        {this.EmailDialogBox()}

        {this.state.showUpdateEmailDialogBox && this.UpdateEmailDialogBox()}

        {this.PasswordDialogBox()}

      </ThemeProvider >
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  // custom style definitions

  dialog: {
    width: "360px",
    height: "300px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center"
  },
  list: {
    display: 'flex',
    padding: '16px 20px',
    gap: '10px',
    borderRadius: '8px',
    alignItems: 'center',
    cursor: 'pointer'
  },
  FieldActive: {
    borderLeft: '#FBBF24 1px solid',
    padding: '4px 16px',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
    cursor: 'pointer',
    color: '#0F172A'
  },
  Fields: {
    borderLeft: '#CBD5E1 1px solid',
    padding: '4px 16px',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#0F172A',
    cursor: 'pointer',
  },
  leftHeadings: {
    color: '#0F172A',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '28px'
  },
  otpInput: {
    height: '56px',
    width: '56px',
    fontSize: '24px',
    margin: '6px',
    borderRadius: '8px',
    border: '1px solid #ccc',
    color: '#4B4C4B',
    gap: '20px'
  },
  verifyButton: {
    backgroundColor: '#FAA81D',
    color: 'white',
    padding: '16 24 16 24',
    fontSize: '16px',
    fontWeight: 700,
  },
  resendText: {
    color: '#0F172A',
    fontSize: '14px',
    fontWeight: 400,
    marginTop: '20px',
  },
  resendLink: {
    color: '#FAA81D',
    cursor: "pointer",
    fontWeight: 700,
    marginLeft: '5px'
  },
  generalFormHeading: {
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '28px',
    color: '#0F172A',
    fontFamily: 'League Spartan , sans-serif'
  },
  generalFormSubHeading: {
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '24px',
    color: '#0F172A',
    fontFamily: 'League Spartan , sans-serif'
  },
  generalFormBoxes: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: ' 12px 16px',
    borderRadius: "8px",
    alignItems: "center",
  },
  accessPermissionTypography: {
    gap: '10px',
    marginLeft: '10px',
    padding: '0',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#0F172A',
    fontFamily: 'League Spartan , sans-serif'
  },
  FormControlCheck: {
    display: 'flex',
    width: '100%',
    padding: '0px',
    margin: '0',
  },
  FormControlCheckLanguage: {
    display: 'flex',
    width: '100%',
    padding: '12px 16px',
    margin: '0',
    justifyContent: 'space-between'
  },
  radioBtn: { margin: 0, padding: 0 },
  formControlLanguageHeading: {
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '24px',
    color: '#0F172A',
    fontFamily: 'League Spartan , sans-serif',
    margin: 0, padding: 0
  },
  BusinessSettingsSubHeadingTypography: {
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    color: '#475569',
    paddingBottom: '4px'
  },
  textAreaBtn: {
    color: '#fff',
    backgroundColor: '#FAA81D',
    alignSelf: 'end',
    padding: '16px 24px',
    fontWeight: 700,
    borderRadius: '8px',
    fontSize: '16px',
  },
  errorText: {
    fontSize: '0.75rem',
    color: '#f44336',
  },
  saveBtn: {
    backgroundColor: '#FAA81D',
    color: '#fff',
    borderRadius: '8px',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px',
    padding: '16px 24px'
  },
  languageTypography: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#0F172A',
    fontFamily: 'League Spartan , sans-serif'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '440px',
    borderRadius: '24px',
  },
  formHeading: {
    fontWeight: 700,
    fontSize: '30px',
    color: '#0F172A',
    letterSpacing: '-2',
    marginBottom: '10px'
  },
  instructions: {
    fontSize: '16px',
    color: '#334155',
    lineHeight: '24px',
    fontWeight: 700
  },
};
// Customizable Area End
